<template>

        <div>
            <!--탑메뉴-->
            <topbar-comp></topbar-comp>
            <left-bar-comp>
                <sports-left-bar-comp></sports-left-bar-comp>
            </left-bar-comp>

            <div class="content_section">
                <div class="content">
                    <board-links-comp></board-links-comp>
                    <div class="board_list">
                        <div class="board_detail">
                            <div class="bd_title">
                                {{item.title}}
                            </div>
                            <div class="bd_author">
                                {{item.user.nickname}}
                            </div>
                            <div class="bd_time">
                                {{item.createTime|datef('MM/DD')}}
                            </div>
                            <div class="bd_cont" v-html="item.content">
                            </div>
                        </div>


                        <CommentsComp :board-id="boardId"
                                      :comment-list="item.commentList"
                                      :commentable="item.commentable" @commentSuccess="commentSuccess()"></CommentsComp>
                    </div>

                </div>
            </div>

            <!-- right 메뉴 -->
            <right-bar-comp>
                <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
            </right-bar-comp>

            <foot-comp></foot-comp>

        </div>

</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import {getBoardDetail, saveBoardComment} from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import SportsBetListComp from "../../components/sports/SportsBetListComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import CommentsComp from "../../components/CommentsComp";
    import BoardLinksComp from "../../components/sports/BoardLinksComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";

    export default {
        name: "BoardDetail",
        components: {
            SportsLeftBarComp,
            BoardLinksComp,
            CommentsComp,
            RightBarBannerComp,
            FootComp, TopbarComp, LeftBarComp, SportsBetListComp, RightBarComp, SubTitle
        },
        data() {
            return {
                sportsConst,
                item: {user: {nickname: ''}, commentList: [], sportsBetInfoList: []},
                boardId: 0
            }
        },
        methods: {
            saveBoardComment() {
                saveBoardComment(this.boardComment).then(res => {
                    if (this.boardComment.content.trim() === '') {
                        this.$swal({
                            title: '댓글 내용을 입력하세요',
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        return false;
                    }
                    if (res.data.success) {
                        this.$swal({
                            title: '댓글 작성이 완료되였습니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.content = ''
                        this.title = ''
                        this.$router.go(0)
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }

                })

            },
            commentSuccess() {
                this.getBoardDetail(this.boardId)
            },
            getBoardDetail() {
                getBoardDetail(this.boardId).then(res => {
                    this.item = {}
                    if (res.data.success) {
                        this.item = res.data.data
                    }
                })
            },

        },
        created() {
            this.boardId = this.$route.params.id
            this.getBoardDetail()
        }
    }
</script>

<style scoped>


</style>